import type { AxiosError } from 'axios';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { ssoErrorCodes } from '@mentimeter/sso-ui/edge';

export function ssoSendError(error: AxiosError, message: string) {
  captureException(
    new MentiError(message, {
      feature: 'sso',
      cause: error,
    }),
  );
}

export const ssoSendVisitorTrackingError = (error: unknown) => {
  captureException(
    new MentiError('Visitor sso tracking exception', {
      feature: 'sso',
      cause: error,
    }),
  );
};

export const isUnknownSSOErrorCode = (errorCode: string | undefined) =>
  !errorCode || !Object.values(ssoErrorCodes).includes(errorCode);
